<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="filters['name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      no-body
    >
      <div>

        <div
          class="m-2"
        >
          <!-- input -->
          <b-row>
            <b-col
              md="4"
              class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
            >
              <b-button
                v-can="'create-role'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                :to="{ name: 'role-add', params: { roleId: null } }"
              >
                <feather-icon icon="PlusIcon" />
                Add New
              </b-button>
            </b-col>
            <b-col
              class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Roles - Page ${page}.xls`"
                :bulkname="`Roles.xls`"
                :fetch="fetchRoleListNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >

          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.name)"
                  variant="light-primary"
                />
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ data.item.name }}
                  </div>
                  <div class="font-small-2 ml-1">
                    {{ data.item.ref_no }}
                  </div>
                </div>
              </div>
            </template>

            <template #cell(is_default)="data">
              <b-badge :variant="data.value ? 'light-success' : 'light-danger'">
                {{ data.value ? 'Yes' : 'No' }}
              </b-badge>
            </template>

            <template #cell(is_active)="data">
              <b-badge :variant="data.value ? 'light-success' : 'light-danger'">
                {{ data.value ? 'Active' : 'Inactive' }}
              </b-badge>
            </template>

            <template #cell(created_at)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>

            <template #cell(action)="data">
              {{ data.value }}
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'edit-role'"
                  :to="{ name: 'role-edit', params: { roleId: data.item.id } }"
                >
                  <feather-icon icon="ToolIcon" />
                  Manage
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'edit-role'"
                  @click="onClickSetActiveStatus(data.item.id, !data.item.is_active)"
                >
                  <feather-icon icon="ToolIcon" />
                  {{ !data.item.is_active ? 'Activate Role' : 'Deactivate Role' }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.is_default"
                  v-can="'delete-role'"
                  @click="onClickDeleteRole(data.item.id)"
                >
                  <feather-icon icon="XIcon" />
                  Delete
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label>Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100 mr-1"
                />
                <!-- <span class="text-muted">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormGroup,
  BAvatar,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const RoleRepository = RepositoryFactory.get('role')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BPagination,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BAvatar,
    BCardActions,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {
          key: 'id', label: 'Ref No', sortable: true, thClass: 'd-none', tdClass: 'd-none',
        },
        { key: 'name', label: 'Role Name', sortable: true },
        { key: 'is_default', label: 'Is Default', sortable: true },
        { key: 'is_active', label: 'Status', sortable: true },
        { key: 'action', label: 'Actions', sortable: false },
      ],
      json_fields: {
        'Role Name': {
          field: 'name',
          callback: value => `"${value}"`,
        },
      },
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchRoleList()
    },
    perPage() {
      this.fetchRoleList()
    },
  },
  mounted() {
    this.fetchRoleList()
  },
  methods: {
    onClickRefresh() {
      this.fetchRoleList()
    },
    onClickDeleteRole(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      // eslint-disable-next-line consistent-return
      }).then(async result => {
        if (result.value) {
          try {
            const res = await RoleRepository.deleteRole(id)
            if (res.status === 200) {
              this.showSuccessMessage('Role deleted successfully')
              this.fetchRoleList()
            } else {
              this.showErrorMessage('Failed to delete role!')
            }
          } catch (e) {
            this.convertAndNotifyError(e)
          }
        }
      })
    },
    async fetchRoleList() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await RoleRepository.getRoleList(this.page, this.filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchRoleListNoPagination() {
      try {
        const { data } = (await RoleRepository.getRoleListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onClickSetActiveStatus(id, active) {
      try {
        const payload = {
          is_active: active,
        }
        const res = await RoleRepository.setActiveStatus(id, payload)
        if (res.status === 200) {
          this.showSuccessMessage(`Role ${active ? 'Activated' : 'Deactivated'} Successfully`)
          this.fetchRoleList()
        } else {
          this.showErrorMessage(`Couldn't ${active ? 'Activate' : 'Deactivate'} Role`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleOk() {
      if (this.cName !== ''
          && this.pCode !== ''
          && this.Stateselected !== null
          && this.conselected !== null
          && this.wareelected !== null
          && this.default_warehouse_selected !== null) {
        // this.setDataforCity()
      } else {
        this.showErrorMessage('Complete All Fields Please')
      }
    },

    // REQUIRED
    applyFilters() {
      this.fetchRoleList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchRoleList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.fetchRoleList()
    },
    changePage(value) {
      this.perPage = value
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../@core/scss/vue/libs/vue-select.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
